<template>
  <div class="page flex-1">
    <header class="header">
      <div class="container">
        <div class="column full">
          <h1>Terms and Conditions</h1>
        </div>
      </div>
    </header>
    <main>
      <div class="container">
        <div class="column full">
          <h3>We will hold up our end, we hope that you will too.</h3>
          <p>Welcome to the Smileonthetiles website
            <strong>(&quot;website&quot;)</strong>. You are required to follow the terms and conditions set out below while using our services. Your use of the
            <strong>website</strong> indicates your acceptance of these terms and conditions which applies to all files on the
            <strong>website</strong>. By downloading content from this
            <strong>website</strong>, you explicitly agree to be bound by the terms of this contract with respect to such content.
          </p>
          <p><strong>Do not download content if you do not accept these terms.</strong></p>
          <h4>1. Entry to the Galleries</h4>
          <p>All users of the gallery must register. Registration is free. Registered users
            <strong>must be over the age of 18 years</strong> and must abide by these terms and conditions. </p>
          <h5>1a. Use of image files</h5>
          <p>
            <strong>Smileonthetiles</strong> licenses to you the use of image files only as provided below on a nonexclusive and nontransferable basis upon payment of the required fees (including where there is no fee in the case of the FREE downloadable images). This is a licence, not a sale. You are permitted to use the images only in the ways stated.
            <strong>Smileonthetiles</strong> continue to own the copyright and intellectual property rights in the images. Third parties who wish to use any images must download them from the
            <strong>smileonthetiles.co.uk</strong> website themselves. All other rights to the image files and any accompanying materials such as copyright are retained by
            <strong>Smileonthetiles</strong>.</p>
          <h4>2. Consent.</h4>
          <p>You <strong>must be at least 18 years of age</strong> to use the
            <strong>website</strong> and accept this agreement.
            <strong>Smileonthetiles</strong> reserves the right to require any registrant provide sufficient proof that they are at least 18 years of age. Minors can access
            <strong>smileonthetiles.co.uk</strong> only by having their parent or legal guardian register on their behalf. By opening such an account, the parent or legal guardian authorises the minor to use and agrees to be bound personally by the terms and conditions of this agreement.
          </p>
          <p>
            <strong>Smileonthetiles</strong> may elect at its sole discretion to monitor any area or records pertaining the
            <strong>website</strong> for compliance with this agreement or for any other reason.</p>
          <h4>3. Intellectual Property and Privacy Laws</h4>
          <p>You acknowledge that the content available through the <strong>website</strong> is the property of
            <strong>Smileonthetiles</strong> and is protected by copyright. Furthermore, you acknowledge that trademarks and copyrights of the subject matter depicted in the images are subject to protection by intellectual property laws and that you must obtain consent from the owners of the intellectual property when necessary to comply with intellectual property laws.
          </p>
          <p>Absent an explicit written statement from
            <strong>Smileonthetiles</strong> stating otherwise, you should assume that no consent from persons or owners of property depicted in an image has been given regarding publication and that model and property releases are not available from
            <strong>Smileonthetiles</strong> or its content providers.</p>
          <h4>4. Prohibited Uses</h4>
          <p>You <strong>MAY NOT</strong>:</p>
          <ul>
            <li>Use images in any way that constitutes a violation of the rights of individuals such as defamation, intrusion on privacy, misappropriation of likeness, or depiction in a false light.
            </li>
            <li>Use images in any way that violates UK laws such as those regulating pornography, obscenity, fraudulent schemes, counterfeiting, espionage, and aid to illicit activities.
            </li>
            <li>Post any file obtained from the Website in a gallery format designed or intended to facilitate transfers of files via the Internet by individual users.
            </li>
            <li>Use images for any purpose that may be damaging or defamitory to the persons depicted within the photo, the venue in which the photo has been taken or to
              <strong>Smileonthetiles</strong> or it's owners or staff.
            </li>
            <li>Reverse engineer, decompile, or disassemble any part of a file or accompanying materials except as permitted by law.
            </li>
            <li>Use any part of an image as a trademark, service mark, logo, or part thereof.</li>
            <li>Remove any copyright or trademark information from any place in the files or accompanying materials.
            </li>
            <li>Display or post multiple images obtained from the
              <strong>website</strong> in a size larger than 480 pixels on the long side in a stand-alone or gallery format that makes it easy for people to copy, save or steal the photos. If more than 10 images from the Website are being used, size must be no more than 150 pixels on the long side. This is to prevent photos from being freely or easily re-distributed.
            </li>
            <li>Use any of the images for business use, without the express written consent of
              <strong>Smileonthetiles</strong>.
            </li>
          </ul>
          <h4>5. Permitted Uses</h4>
          <p>You <strong>MAY</strong>:</p>
          <ul>
            <li>Display the images in any manner not prohibited by the agreement (see Section 4.) provided that you include the copyright information from the image file in the form of a legally effective copyright notice if at all possible in this form: &quot;&copy; Image from
              <strong>smileonthetiles.co.uk</strong>&quot;.
            </li>
            <li> - Acceptable uses include but are not limited to electronic or print media, websites, newsletters, and educational materials.
            </li>
            <li>Print a copy of the photo for your own use in any size, but you may not resell it, or redistribute it to others in any way.
            </li>
          </ul>
          <h4>6. Discontinuance</h4>
          <p>
            <strong>Smileonthetiles</strong> reserves the right to elect at a later date to replace the image file(s) with an alternative for any reason. Upon notice of such replacement, the license for the replaced image file(s) terminates for any products that do not already exist, and this license automatically applies to the replacement image file(s). You agree not to use the replaced image file(s).
          </p>
          <h4>7. No resale or sublicensing</h4>
          <p>You may not resell, distribute or sublicense the files, accompanying material, or the rights to use the files to anyone for any purpose, except as specifically provided for in this agreement.</p>
          <h4>8. Assumption of risk</h4>
          <p>You expressly agree that use of all images, files, or software distributed by
            <strong>Smileonthetiles</strong> is at your sole risk.</p>
          <h4>9. Disclaimers</h4>
          <p>All images, files, and software distributed by
            <strong>Smileonthetiles</strong> are provided on an &quot;as is&quot; and &quot;as available&quot; basis and without warranties or conditions of any kind either express or implied.
            <strong>Smileonthetiles</strong> assumes no responsibility for any actions or liabilities arising from their possession or use.
          </p>
          <p>
            <strong>Smileonthetiles</strong> disclaims all warranties, express or implied, including but not limited to, implied warranties of merchantability and fitness for a particular purpose. Under no circumstances shall
            <strong>Smileonthetiles</strong> or its content providers be liable to you or any third-party for any indirect, consequential, incidental, special or punitive damages, including but not limited to; lost profits and business interruption, irrespective of the type of cause of action, arising in any way from use of the
            <strong>website</strong>, even if
            <strong>Smileonthetiles</strong> is expressly advised of the possibility of such damages.<br/><br/> In no event shall
            <strong>Smileonthetiles’</strong> liability exceed the fees paid for the use of the files. No oral advice or written information given by
            <strong>Smileonthetiles</strong>, its content providers or the like, shall create a warranty; nor shall customer rely on any such information or advice.<br/><br/> You acknowledge that this paragraph shall apply to all content, images, and services available through this
            <strong>website</strong>. In those countries that do not allow the exclusion or limitation of liability for consequential or incidental damages, liability is limited to the fullest extent permitted by law.
          </p>
          <h4>10. Assignment</h4>
          <p>You may not assign its right and obligations under this agreement without obtaining the prior written consent of
            <strong>Smileonthetiles. Smileonthetiles</strong> may assign this agreement.</p>
          <h4>11. Severability</h4>
          <p>Should any clause of this agreement be found unenforceable, that will not affect any other clause and each will remain in full force and effect.</p>
          <h4>12. Legal Fees and Jursdiction</h4>
          <p>If
            <strong>Smileonthetiles</strong> is obligated to go to court, to enforce any of its rights, or to collect any fees, you agree to reimburse
            <strong>Smileonthetiles</strong> for its legal fees, costs and disbursements if
            <strong>Smileonthetiles</strong> is successful.</p>
          <h4>13. Entire Agreement</h4>
          <p>
            <strong>YOU ACKNOWLEDGE THAT YOU HAVE READ THIS AGREEMENT, UNDERSTAND IT, AND AGREE TO BE BOUND BY ITS TERMS AND CONDITIONS. YOU FURTHER AGREE THAT IT IS THE COMPLETE AND EXCLUSIVE STATEMENT OF THE AGREEMENT BETWEEN YOU AND Smileonthetiles, WHICH SUPERSEDES ANY PROPOSAL OR PRIOR AGREEMENT, ORAL OR WRITTEN, AND ANY OTHER COMMUNICATION BETWEEN YOU AND Smileonthetiles RELATING TO THE SUBJECT OF THIS AGREEMENT.</strong>
          </p>
          <p>You agree that by using this site or images, photos or files from this site, that you are bound by this Agreement. This Agreement evidences the complete understanding of the parties
            <strong>(you and Smileonthetiles)</strong> with respect to the subject matter hereof and may not be amended, modified or waived in whole or in part except in a writing signed by the parties.
          </p>
        </div>
      </div>
    </main>
    <site-footer/>
  </div>
</template>

<script>
  import SiteFooter from '../components/SiteFooter.vue'

  export default {
    name: 'terms',
    components: { SiteFooter }
  }
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  @import "../styles/env";
</style>
