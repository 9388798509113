<template>
  <div class="page flex-1">
    <header class="header">
      <div class="container">
        <div class="column full">
          <h1>Privacy Policy</h1>
          <h2>We care about your privacy</h2>
        </div>
      </div>
    </header>
    <main>
      <div class="container">
        <div class="column full">
          <p>This policy covers <strong>Smileonthetiles'</strong> use of personal information that
            <strong>Smileonthetiles</strong> collects when you use
            <strong>smileonthetiles.co.uk</strong>. The policy also gives you information about cookies,
            <strong>Smileonthetiles'</strong> and third parties' use of cookies and how you may reject such cookies.</p>
          <h3>General Data Protection Regulation</h3>
          <p>To comply with EU Regulation (EU) 2016/679, this privacy policy sets out what data is collected from users of
            <strong>smileonthetiles.co.uk</strong> and related services, and explains how that data is used, shared, and your rights in regards to that data.
            <strong>Smileonthetiles</strong> stores two distinct classes of data from users of its services: data which you yourself submit to us, and data which we automatically collect from you whilst you use our services. Our aim is to deliver our services whilst collecting the minimum amount of data from users.
          </p>
          <p>In order to use the
            <strong>smileonthetiles.co.uk</strong> website, we require users to authenticate by one of three methods: with their Google account, with their Facebook account, or with an email address. The privacy policies for these sign-in providers can be found at the following links:
          </p>
          <ul>
            <li>
              <a href="https://developers.google.com/terms/api-services-user-data-policy" target="_blank" rel="nofollow">Google</a>
            </li>
            <li>
              <a href="https://www.facebook.com/privacy/explanation" target="_blank" rel="nofollow">Facebook</a>
            </li>
            <li>
              <a href="https://firebase.google.com/support/privacy/" target="_blank" rel="nofollow">Google Firebase</a> (for email login)
            </li>
          </ul>
          <p>Website visitor statistics are collected in the background from all users who visit the Site. This statistical data is mostly anonymous, although does include IP addresses.
            <strong>Smileonthetiles</strong> is not able to link this data with registered user accounts, so users are not personally identifiable by IP address. Statistical data is purely for internal use and helps us to identify user trends and improve the user experience for all vistors.
          </p>
          <h3>Use and storage of your personal information</h3>
          <p>When you supply any personal information to
            <strong>smileonthetiles.co.uk</strong> we have obligations towards you in the way we use that data. We explain how we will use it and tell you if we want to pass the information on to anyone else.
          </p>
          <p>In general, any information you provide to <strong>Smileonthetiles</strong> will only be used within
            <strong>Smileonthetiles</strong> and by its agents and service providers. Your information will be disclosed where we are obliged or permitted by law. Also, if you post or send offensive, inappropriate or objectionable content anywhere on or to
            <strong>smileonthetiles.co.uk</strong> or otherwise engage in any disruptive behaviour on
            <strong>smileonthetiles.co.uk</strong>,
            <strong>Smileonthetiles</strong> can use whatever information that is available to it about you to stop such behaviour. This may involve informing relevant third parties such as your employer, school, e-mail/Internet provider, and law enforcement agencies about the content and your behaviour.
          </p>
          <p>We will hold your personal information on our systems for as long as you use the service you have requested, and remove it in the event that the purpose has been met. If you wish to have your information removed immediately, please email
            <a href="mailto:support@smileonthetiles.co.uk">support@smileonthetiles.co.uk</a>. </p>
          <p>Certain website statistical information is stored indefinitely for internal use by
            <strong>Smileonthetiles</strong>. This includes, but is not limited to: IP addresses, device type, device operating system, web browser type, visit duration, and connection origin. None of these metrics are linked, so cannot be used to personally identify or profile any user. This information is used purely internally within
            <strong>Smileonthetiles</strong> for the purposes of optimising our services and never shared with third parties. The data is not used to tailor your experience on
            <strong>smileonthetiles.co.uk</strong> or deliver personalised services. None of this data, other than IP address, is linked to user accounts on
            <strong>smileonthetiles.co.uk</strong>.
          </p>
          <p>For safety reasons,
            <strong>Smileonthetiles</strong> may store messaging transcript data (including message content, user names, times and dates) arising from the use of
            <strong>smileonthetiles.co.uk</strong> services for a period of six months. </p>
          <p>
            <strong>Smileonthetiles</strong> uses third party payment providers for its shop services. Please see the privacy policy of those providers to see how they handle your data.
            <strong>Smileonthetiles</strong> does not store any payment information. </p>
          <p><strong>Payment providers: <a href="https://www.paypal.com/uk/home" target="_blank" rel="nofollow">PayPal</a>
            <a href="https://stripe.com/gb" target="_blank" rel="nofollow">Stripe</a></strong></p>
          <h3>Access to your personal information</h3>
          <p>You have the right to request a copy of the personal information
            <strong>Smileonthetiles</strong> holds about you and to have any inaccuracies corrected, or to have the data deleted. Please email your request to
            <a href="mailto:privacy@smileonthetiles.co.uk">privacy@smileonthetiles.co.uk</a> and we will act as fast as reasonably possible. Please be aware that due to the nature of the content we hold and how it is stored, it may not be reasonably practical for us to fulfil certain requests. In these cases, we will advise to what extent we are able to comply and what action we me may need you to take in order assist us in completing your request. We may charge a fee to fulfil requests which are manifestly unfounded or excessive.
          </p>
          <h3>Cookies</h3>
          <p>The Site uses cookies to help you personalise your online experience. A cookie is a text file that is placed on on your computer by a web server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie to you.</p>
          <p>One of the primary purposes of cookies is to tell the website that you have returned to a specific page, so that when you visit the site again, the experience will be personalised. You have the ability to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to set your computer to accept cookies, you may also choose to be logged into the Site automatically.</p>
          <p>When someone visits the site, a set of cookies are placed on the user's computer (if the user accepts cookies) or are read if the user has visited the Site previously. One use of cookies is to assist in the collection of the site visitation statistics described above.</p>
          <p>If you choose to not have your browser accept cookies, you may not be able to access our website.</p>
          <p><strong>For further information on cookies please visit
            <a href="https://www.aboutcookies.org" target="_blank" rel="nofollow">aboutcookies.org</a>.</strong>
          </p>
          <h3>Facebook Data</h3>
          <p>
            <strong>Smileonthetiles</strong> uses Facebook integration to provide the ability to post your photos to your Facebook timeline. During use of this process you are able to accept or decline use of your information to perform this action. By clicking
            <strong>accept</strong>,
            <strong>Smileonthetiles</strong> will automatically post the selected image to your profile on your behalf. No private information will be collected while using this feature except your Email Address and Basic Profile Information. None of your information will be saved or re-used.
          </p>
          <p>Only the photo will be added to your Timeline and no other information will be posted.</p>
          <h3>Security</h3>
          <p><strong>Smileonthetiles</strong> does its best to ensure that all of your activity on
            <strong>smileonthetiles.co.uk</strong> is private. All web pages on
            <strong>smileonthetiles.co.uk</strong> are delivered to your device using encryption, ensuring your browsing cannot be monitored or intercepted by third parties. Visitors can verify this by looking for the padlock icon in the browser address bar. This also verifies the identity of the website.
          </p>
          <h3>Links to third party websites</h3>
          <p>This Site may contain links to other web sites. Please be aware that this privacy statement applies solely to the information collected on the
            <strong>smileonthetiles.co.uk</strong> site. We are not responsible for the privacy practices or the content of other web sites. We encourage you to be aware when you are leaving this Site and read the privacy statements of each and every web site that you visit before providing any personally identifiable information. If you find that these sites are using your information in an insecure way, please contact us so we can remove those links from our website.
          </p>
          <h3>Minors</h3>
          <p>If you are aged 18 or under, please ask your parent or guardian to register for you in line with our terms and conditions of site use. You must have the permission of your parent or guardian beforehand whenever you provide personal information to the
            <strong>Smileonthetiles</strong> website. Users without this consent are not allowed to provide us with personal information.
          </p>
          <h3>Changes to this Statement</h3>
          <p>
            <strong>Smileonthetiles</strong> may occasionally update this privacy statement. We encourage you to periodically review this privacy statement to be informed of how
            <strong>smileonthetiles.co.uk</strong> is helping to protect your information.</p>
        </div>
      </div>
    </main>
    <site-footer/>
  </div>
</template>

<script>
  import SiteFooter from '../components/SiteFooter.vue'

  export default {
    name: 'privacy',
    components: { SiteFooter }
  }
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  @import "../styles/env";
</style>
